import './App.css';
import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import Home from './Pages/Home';

function App() {
  return (
    <React.Fragment>
      <Router>
        <Routes>
          <Route path="/*" element={<Home/>}/>
        </Routes>
      </Router>
    </React.Fragment>
  );
}

export default App;
