import React, { useState } from "react";
import Header from "../Elements/Header";
import Carousel from "../Elements/Carousel";
import YouTube from 'react-youtube';
import './Home.css';

function Home() {
  const [ytStatus, setYtStatus] = useState(<></>);
  const [isYtLoaded, setIsYtLoaded] = useState(true);

  const onYoutubeError = () => {
    setIsYtLoaded(false);
    setYtStatus(
      <div className="Message-error">
        There was some error connecting with YouTube.<br/>
        To view teaser visit: <a href="https://youtu.be/jNAoF7VENTg">https://youtu.be/jNAoF7VENTg</a>
      </div>
    );
  };

  return (
    <React.Fragment>
      <Header background={`url('${process.env.PUBLIC_URL}/Images/Background1.jpg')`}>
        <img alt="Rage Disco logo" className="Center-object" src="/Images/Logo.png"/>
        <h1>
          LASERS PILLS DRINKS
        </h1>
      </Header>

      <div className="Section Section-content Section-image-bg Section-high s1">
        <div className="Section-bg-div">
          <video autoPlay muted loop className="Section-video-bg">
            <source src={process.env.PUBLIC_URL+`/Images/Background2.mp4`} type="video/mp4"/>
          </video>
        </div>
        <div className="Section-left Text-center Text-primary Section-item">
          <h3>ABOUT RAGE DISCO</h3>
          <p>Rage Disco is an intense action game where psychedelic substances drive a randomly evolving party filled with deadly, strobing lasers.</p>
          <p>Mind-bending pills crank up all the crazy events going down on the dance floor. They cause surreal visuals and may affect movement, among many other effects. It is a randomly generated party where areas, enemies and custom-made music are transforming based upon your actions. Blazing, vibrant lasers and flashy enemies are a mortal danger, especially when your senses are completely overwhelmed. </p>
        </div>
      </div>

      <div className="Section Section-content s1">
        <div className="Section-centered Text-center Text-primary Section-item Yt-section">
          <h3>TEASER</h3>

          {isYtLoaded ? <YouTube className="Yt-player" videoId="jNAoF7VENTg" onError={onYoutubeError}/> : ytStatus}
        </div>
      </div>

      <div className="Section">
        <div className="Section-bg-div">
          <video autoPlay muted loop className="Section-video-bg">
            <source src={process.env.PUBLIC_URL+`/Images/Background3.mp4`} type="video/mp4"/>
          </video>
        </div>
        <div className="Section-content Section-image-bg Section-high s1">
          <div className="Section-left Text-center Text-primary Section-item">
            <h3>INTENSE ACTION</h3>
            <p>Enjoy the fast-paced party packed full of deadly, strobing lasers!</p>
            <p>Blazing, colorful lasers and flashy enemies become a mortal danger if you are not careful enough.</p>
            <p>Avoiding every threat is essential for your survival, especially when your senses are completely overwhelmed.</p>
          </div>
        </div>
      </div>
      

      <div className="Section Section-content Section-image-bg Section-high s1">
        <div className="Section-bg-div">
          <video autoPlay muted loop className="Section-video-bg">
            <source src={process.env.PUBLIC_URL+`/Images/Background4.mp4`} type="video/mp4"/>
          </video>
        </div>
        <div className="Section-right Text-center Text-primary Section-item">
          <h3>PSYCHEDELIC SUBSTANCES</h3>
          <p>Mind-bending pills mixed with exotic cocktails crank up all the crazy events going down on the dance floor.</p>
          <p>They cause surreal visuals and may affect movement, among many other effects.</p>
          <p>Prove that you can handle the crazy, confusing and synesthetic experience created for you at Rage Disco! </p>
        </div>
      </div>

      <div className="Section Section-content Section-image-bg Section-high s1" style={{backgroundImage: `url('${process.env.PUBLIC_URL}/Images/Background5.jpg')`}}>
        <div className="Section-left Text-center Text-primary Section-item">
          <h3>EVOLVING PARTY</h3>
          <p>Levels are randomly generated and constantly evolving based upon your actions.</p>
          <p>It is a party where areas, enemies and traps are transforming during a single level.</p>
          <p>Energizing and custom-made music responds to your choices. </p>
        </div>
      </div>

      <div className="Section Section-content s1">
        <div className="Section-centered Text-center Text-primary Section-item">
          <h3>SCREENSHOTS</h3>
          <Carousel images= {[
          "Images/Carousel/1.jpg",
          "Images/Carousel/2.jpg",
          "Images/Carousel/3.jpg",
          "Images/Carousel/4.jpg",
          "Images/Carousel/5.jpg",
          "Images/Carousel/6.jpg"
        ]}/>
        </div>
      </div>

      <div className="Section Section-content Section-image-bg s1" style={{backgroundImage: `url('${process.env.PUBLIC_URL}/Images/Background6.jpg')`}}>
        <div className="Section-centered Text-center Text-primary Section-item">
          <h3>Rage Disco was never released despite the three years long development time.</h3>
          <h3>In year 2018 project was suspended for an indefinite period. </h3>
        </div>
      </div>

      <div className="Section Section-content s1">
        <div className="Section-item Center Text-primary">
          <div className="Developer-text"><h3>Rage Disco was developed by</h3></div>
          <div className="Developer-logo">
            <a href="suicidepenguin.com">
              <img alt="Suicide Penguin logo" src="/Images/SuicidePenguin-logo.png"/>
            </a>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Home;