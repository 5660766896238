import { useState, useEffect, useCallback } from "react";
import Icon from '@mdi/react';
import { mdiCircle, mdiChevronRightCircle, mdiChevronLeftCircle } from '@mdi/js';
import './Carousel.css';

function CarouselImage(props: {
  img: any;
  isVisible: boolean
}) {
  const img: any = new Image();
  img.src = props.img;

  return(
    <div className="Carousel-item">
      <img alt={img} className="Carousel-image" src={img.src} style={props.isVisible ? {visibility: "visible"} : {visibility: "hidden" }}/>
    </div>
  );
}

function Carousel(props: {
  images: string[];
  periodInSec?: number;
}) {
  const [currentImage, setCurrentImage] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [isActive] = useState(true); // for future
  const period = 8;

  const dots = props.images.map((p: string, i: number) => {
    return (
      <div key={p} className={i === currentImage ? "Carousel-dot Carousel-current" : "Carousel-dot"}>
        <Icon path={mdiCircle} size={0.5}/>
      </div>
    );
  });

  const nextImage = useCallback(() => {
    setCurrentImage(currentImage === props.images.length-1 ? 0 : currentImage+1);
    setSeconds(0);
  }, [currentImage, props.images.length]);

  const prevImage = () => {
    setCurrentImage(currentImage === 0 ? props.images.length-1 : currentImage-1);
    setSeconds(0);
  }

  useEffect(() => {
    let interval: any = null;
    if (isActive) {
      interval = setInterval(() => {
        if (seconds + 1 === period){
          nextImage();
        } else {
          setSeconds(seconds => seconds + 1);
        }
      }, 1000);
    } else if (!isActive && seconds !== 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isActive, seconds, nextImage]);

  const imagesDraw = props.images.map((img: string, index: number) => 
    <CarouselImage key={img} img={img} isVisible={index === currentImage}/>
  );

  return(
    <div className="Carousel">
      {imagesDraw}
      <div className="Carousel-overlay Carousel-bottom Carousel-dots noselect">
        {dots}
      </div>
      <div className="Carousel-overlay Carousel-button Carousel-left noselect"
        onClick={prevImage}
      >
        <Icon path={mdiChevronLeftCircle} size={1.5}/>
      </div>
      <div className="Carousel-overlay Carousel-button Carousel-right noselect"
        onClick={nextImage}
      >
        <Icon path={mdiChevronRightCircle} size={1.5}/>
      </div>
    </div>
  );
}

export default Carousel;