import './Header.css';

function Header(props: {
  background: any;
  children?: any;
}) {
  return (
  <div className="Section Header" style={{backgroundImage: props.background}}>
    <div className=" Section-content s1">
      <div className="Section-centered Text-center Text-primary Section-item">
        {props.children}
      </div>
    </div>
  </div>
  );
}

export default Header;